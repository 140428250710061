import type { ZudokuConfig } from "zudoku";
import schema from './openapi.json'

const config: ZudokuConfig = {
  topNavigation: [
    { label: "API Reference", id: "api" },
  ],
  redirects: [
    { from: "/", to: "/api" },
  ],
  apis: {
    type: "json",
    input: schema,
    navigationId: "api",
  },

};

export default config;
